/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// https://fonts.google.com/icons
@import 'material-symbols';


// @import '@ionic/angular/css/palettes/dark.always.css';
// @import '@ionic/angular/css/palettes/dark.class.css';
// @import '@ionic/angular/css/palettes/dark.system.css';

// https://ionicframework.com/docs/theming/dark-mode
/* (Optional) This is added to prevent the flashing that happens when toggling between palettes */
ion-item {
    --transition: none;
  }

// .toast-container {
//         --background: #067402;
//     }

// $toast-ios-background: #067402; // Apply for iOS
// $toast-md-background: #067402; // Apply for Android
// $toast-wp-background: #067402; // Apply for Windows
// .toast-content {
//     // background-color: #067402;
//     background-color: blue;
//     }
//     .toast-container {
//         background-color: blue;
//     }   
// .toast-wrapper {
    // background-color: green !important;
    // color: white !important;
// }

// ion-toast>.toast-wrapper {
//     background-color: red !important;
// }


[hidden] {
    display: none !important;
}


ion-toast.measurementAddedToList-toast {

    // background: unset !important;
    // --background: red;

    // color: var(--ion-color-light);
    // --background: #067402;
    --background: #026e00;
    color: white;

    // --color: #067402;

    // .toast-wrapper {
        // background-color: green !important;
        // background: blue !important;
        // color: white !important;
    // }
    // .toast-content {
        // background-color: #067402;
    // }
    // --background: #067402;

    // --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    font-weight: bold;
    font-size: 130%;
    text-align: center;
    // --color: #d6d6d6;


    margin-top: 50px;
    opacity: 80%;
    // color: white;
}

// .measurementAddedToList-toast {
//     margin-top: 50px;
//     opacity: 80%;
//     color: white;
//   }

ion-toolbar {
    --min-height: 48px;
}


ion-modal.edit-value-popup {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.edit-value-popup h1 {
    margin: 20px 20px 10px 20px;
}

ion-modal.edit-value-popup ion-icon {
    margin-right: 6px;
    width: 48px;
    height: 48px;
    padding: 4px 0;
    color: #aaaaaa;
}

ion-modal.edit-value-popup .wrapper {
    margin-bottom: 10px;
}